import * as Sentry from "@sentry/nuxt";

Sentry.init({
  dsn: "https://6258700483c24ea2b61e4ce748f31fe7@errortracker.pbsecure.pp.ua/1",
  tracesSampleRate: 0.01,
  integrations: [
    Sentry.httpClientIntegration({
        failedRequestStatusCodes: [400, 401, 403, 404, 429, 500, 502, 503, 504],
    }),
    Sentry.captureConsoleIntegration({
        levels: ["error", "warning"],
    })
  ],

  // This option is required for capturing headers and cookies.
  sendDefaultPii: true,
});
